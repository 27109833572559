<template>
  <v-app>
    <dashboard-core-app-bar ref="appBar" v-if="getShowAppBar" @selectLanguageEvent="selectLanguage" />
    
    <!-- <loginView ref="loginView" @selectLanguageEvent="selectLanguage" /> -->

    <dashboard-core-drawer ref="navDrawer" :parentComponent="$refs" />

    <dashboard-core-view ref="view" :parentComponent="$refs" />

    <!-- <dashboard-core-settings /> -->
    <dashboard-core-footer ref="footer" :parentComponent="$refs"/>
  </v-app>
</template>

<script>
  // import DashboardCoreDrawer from './components/core/Drawer'
  // import DashboardCoreAppBar from './components/core/AppBar'
  import { mapMutations, mapGetters, mapState } from 'vuex'
  /* import SqreenFooter from './components/core/Footer.vue' */
  export default {
    name: 'DashboardIndex',

    components: {
      // DashboardCoreAppBar,
      // DashboardCoreDrawer,
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
      DashboardCoreFooter: () => import('./components/core/Footer'),
      /* loginView: () => import('./components/login'), */
      /* SqreenFooter, */
    },

    data: () => ({
      expandOnHover: false,
    }),

    computed: {
      ...mapGetters(['getShowAppBar']),
    },

    created () {
    },

    mounted () {
    },

    methods: {
      selectLanguage (language) {
        this.$refs.navDrawer.getMenu(this.$i18n.locale)
        this.$refs.view.selectLanguage(language)
      }
    },
  }
</script>
